import React from 'react'
import './MainSlider.scss';

export default class MainSlider extends React.Component {

	state = {
		slides: venueData.plantImages
	}
  componentDidMount(){

    let slider = $(".venue_slider .bxslider").bxSlider({
								mode:'horizontal',
								auto:true,
								stopAuto:false,
                slideMargin: 0,
                speed: 1000,
								slideWidth:0,
                controls: false,
                randomStart: false,
                preloadImages: "all",
                adaptiveHeight: false,
                responsive: true,
                touchEnabled: true,
                nextText: "",
                prevText: "",
								pagerCustom: '#bx-pager',
								OnSliderAfter: function(e){
								slider.stopAuto();
								slider.startAuto();
								}

  });
}
  renderItem(item){
		// console.log(venueData);
		// console.log(item);
		// console.log(venueData.plantImages);
    return (
			<img key={`slide_${item.id}`} src={item.url}/>
    );
  }

	renderList(item){
	let index = arguments[1];
	// console.log(item);
    return (
					<a data-slide-index={index}>
						<img src={item.url} name=""/>
					</a>
    );
  }

  render () {
		// console.log(this.state.slides);
    if(this.state.slides.length == 0){
      return (
        <div className="cleafix" style={{height: '180px'}}></div>
      );
    }
			return (

				<div>
					<div className="venue_slider col-xs-12 col-sm-10 col-md-10 col-lg-10">
						<div className="bxslider">
							{this.state.slides.map(this.renderItem)}
						</div>
					</div>

					<div id="bx-pager">
						{this.state.slides.map(this.renderList)}
					</div>
				</div>
			);

  }
}
