import React from 'react';
import './OptionsNearby.scss';
export default class OptionsNearby extends React.Component {

	state = {
		venues: []
	}

	componentDidMount(){
		var _this=this;
      $.post( options.restURL + "/plantswithindistance/", {plantId: this.props.venue, distanceInMeters: "10000" }, function( a ) {
        a = a.plants_data;
        var r = [];
        var i=0;
        for (var n in a){
          a[n].id = n,
          r[n] = a[n];
          i++;
          if (i === 4) { break; }
        }
        _this.setState({venues: r});
      });

	}

	renderVenue(venue, i){
		var url = options.venuePageUrl + "/" + venue.nameForUrl + "_" + venue.plantId;

		let image;

		if (venue.images) {
        image = venue.images.shift();
    }
		return (
			<div key={`venue_${venue.plantId}`} className="nearbyVenue">
				<div className="nearby_item">
					<a href="#">
						<div className="appearance" style={{ backgroundImage: `url(${image.image})`}}>

						</div>
					</a>
					<div className="nameWrapper">
						<p className="venueName">{ venue.name }</p>
					</div>
					<div className="description">
						<p className="venueFacts">{ venue.factsAboutPlant }</p>
					</div>
				</div>
			</div>
		);
	}

  render () {
		if(this.state.venues.length == 0){
			return (<div>Loading...</div>);
		}
// console.log(this.state.venues[0].images[0].image);

// replase nearby facility name
this.state.venues[0].name="Grand Hotel";
this.state.venues[1].name="Wärdshuset";
this.state.venues[2].name="Villa Maritime";
this.state.venues[3].name="Carlstens Fästning";

// replase nearby facility img
this.state.venues[0].images[0].image="assets/images/facilities/villaMaritime.jpg";
this.state.venues[1].images[0].image="assets/images/facilities/wardshuset.jpg";
this.state.venues[2].images[0].image="assets/images/facilities/grandHotell.jpg";
this.state.venues[3].images[0].image="assets/images/facilities/carlstensFästning.jpg";

    return (
			<div className="venue_nearby v_info" id="options_nearby">
				<div className="venue_nearby v_info">
					<div className="heading">
						<h2 className="name">{ lang.venueOptionsNearby }</h2>
					</div>
					<div className="row contentContainer">
						{ this.state.venues.map(this.renderVenue) }
					</div>
				</div>
			</div>
    );
  }
}
