import React from 'react';


export default class PersonsPicker extends React.Component {

	state = {
		value: getParams.persons ? getParams.persons.replace("+", " ") : ""
	}

	componentDidMount(){
		$("[name=persons]").change(function() {
            createSearch()
            if($(this).val()) $(this).removeClass('error');
            if($("[name=date]").val() && $("[name=persons]").val()) $(".venue .venue_filters .ok").hide();
        })
	}

	changeHandle(event){
		this.setState({
        value: event.target.value
    })
	}

  render () {
		let value = this.state.value;
    return (
      <div className="col-sm-3 col-sm-offset-0 col-md-3 col-md-offset-0 col-xs-6 col-xs-offset-0 person float-right">
				<div className="styled_select">
					<select className="selectBoxTimeOfMeeting"
						onChange={(e) => this.props.updateTime(e.target.value)}
					>
				   <option value="fullDayPrice">Hela dagen</option>
				   <option value="preNoonPrice">Förmiddagen</option>
				   <option value="afterNoonPrice">Eftermiddagen</option>
				 </select>

				</div>
      </div>
    );
  }
}
