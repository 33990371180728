import React from 'react';
import './VenueMap.scss';


export default class VenueMap extends React.Component {

	componentDidMount(){
		var locations = [
		 ['Havshotellet', venueData.visitingAddress.lat, venueData.visitingAddress.long, 1],
		 ['Grand Hotel', 57.8873548, 11.5808801, 2],
		 ['Marstrands Wärdshus', 57.886895, 11.5825645, 3],
		 ['Villa Maritime', 57.885155, 11.582501, 4],
		 ['Carlstens Fästning', 57.8861067, 11.5765128, 5]
	 ];

		let t = {
        center: new google.maps.LatLng(venueData.visitingAddress.lat, venueData.visitingAddress.long),
        zoom: 15,
        zoomControl: 1,
				scrollwheel: false,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.DEFAULT
        },
        disableDoubleClickZoom: !0,
        mapTypeControl: !0,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR
        },
        scaleControl: !0,
        scrollwheel: !0,
        panControl: !0,
        streetViewControl: !0,
        draggable: !0,
        overviewMapControl: !0,
        overviewMapControlOptions: {
            opened: !1
        },
        mapTypeId:google.maps.MapTypeId.HYBRID,
				scrollwheel: false
    }
      , a = document.getElementById("map")
      , r = new google.maps.Map(a,t);

			var infowindow = new google.maps.InfoWindow();

     var marker, i;

     for (i = 0; i < locations.length; i++) {
       marker = new google.maps.Marker({
         position: new google.maps.LatLng(locations[i][1], locations[i][2]),
         map: r
       });

       google.maps.event.addListener(marker, 'click', (function(marker, i) {
         return function() {
           infowindow.setContent(locations[i][0]);
           infowindow.open(map, marker);
         }
       })(marker, i));
     }


}


  render () {


    return (
			<div className="venue_map v_info" id="venue_map">
				<div>
					<div className="heading">
					</div>
					<div id="map"></div>
	      </div>
			</div>
    );
  }
}
