import React from 'react';
import './SlideShow.scss';
import FaciliteInformationBox from '../FaciliteInformationBox';





export default class SlideShow extends React.Component {

	state = {
		slides: venueData.plantImages
	}

	renderSmallImg(item){
    return (
			<img id="noMarginOrPadding" src={item.url} className="slideImg col-xs-12 col-sm-2 col-md-2" name=""/>
    );
  }

	render () {
		// console.log(venueData.plantFacts);
    return (

			<div className="slideShow row">
				<img id="noMarginOrPadding" className="mainSlideImg col-xs-12 col-sm-12 col-md-10" src={venueData.plantImages[0].url}/>
				{this.state.slides.map(this.renderSmallImg)}
				<div className="FacilitiesInfo col-xs-12 col-sm-12 col-md-10">
					<FaciliteInformationBox/>
				</div>
			</div>

    );
  }
}
