import React from 'react'
import './RoomCardSlider.scss';


export default class RoomCardSlider extends React.Component {

	state = {
		slides: e.imgUrl
	}

  componentDidMount(){

    let slider = $(".venue_slider .bxslider").bxSlider({
								mode:'horizontal',
								stopAuto:false,
                slideMargin: 0,
								slideWidth:0,
                speed: 1000,
                controls: false,
                randomStart: false,
                preloadImages: "all",
                adaptiveHeight: false,
                responsive: true,
                touchEnabled: true,
                nextText: "",
                prevText: "",
								pagerCustom: '#bx-pager',
								OnSliderAfter: function(e){
								slider.stopAuto();
								slider.startAuto();
								}

  });
}
  renderItem(item){
		// console.log(item);
    return (
      <li key={`slide_${item.id}`} style={{backgroundImage: `url(${item.url})`}}></li>
    );
  }

	renderList(item){
	let index = arguments[1];
	console.log(index);
    return (
			<div className="row">
					<a data-slide-index={index}>
						<img src={item.url}/>
					</a>
			</div>
    );
  }

  render () {
		// console.log(this.state.slides[0].url);
    if(this.state.slides.length == 0){
      return (
        <div className="cleafix" style={{height: '180px'}}></div>
      );
    }
			return (

				<div className="row">

					<div id="slideShow" className="col-xs-12 col-sm-10 col-md-10 col-lg-10 venue_slider">
						<ul className="bxslider">
							{this.state.slides.map(this.renderItem)}
						</ul>
					</div>

					<div id="bx-pager" className="col-sm-1 col-md-1 col-lg-1">
						{this.state.slides.map(this.renderList)}

						<div className="row">
								<a data-slide-index="0" href="">
									<img key="1" src="https://dev-be.timetomeet.se/static/crb/media/20170201/Skarmavbild_2017-02-01_kl._09.33.24.png"/>
								</a>
						</div>

						<div className="row">
								<a data-slide-index="0" href="">
									<img key="2" src="https://dev-be.timetomeet.se/static/crb/media/20170201/Skarmavbild_2017-02-01_kl._09.33.24.png"/>
								</a>
						</div>

					</div>

				</div>
			);

  }
}
