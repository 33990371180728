import React from 'react'

import './ShowSearchForm.scss';


import PersonsPicker from '../PersonsPicker';
import ReactDatePicker from '../ReactDatePicker';
import TimeOfMeeting from '../TimeOfMeeting';

export default class ShowSearchForm extends React.Component {
    componentDidMount(){
        $(".venue_filters .ok a").click(function(e) {
            e.preventDefault(),
            $(".content").removeClass("darker_nr"),
            $(".venue_filters .row .person, .venue_filters .row .date, .venue_filters .row .time").removeClass("col-md-6"),
            $(".venue_filters .row .person, .venue_filters .row .date, .venue_filters .row .time").addClass("col-md-5"),
            $(".venue_filters").removeClass("fill_it")
        })
    }
  render () {
    return (<div>
            <div id="search_form" className="row">
	              <form id="second_search_form">
		                <PersonsPicker />
		                <ReactDatePicker />
		                <TimeOfMeeting time={this.props.time} updateTime={this.props.updateTime}/>
	          		</form>
            </div>
						<div className="pull-left addonsNext" id="noMarginOrPadding">
							<p><div dangerouslySetInnerHTML={{__html: lang.addonsNext}}></div></p>
						</div>
						</div>
    );
  }
}
