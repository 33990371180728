import React from 'react';

import './Venue.scss';

import VenueImage from '../VenueImage';
import Facilities from '../Facilities';
import VenueFilters from '../VenueFilters';
import OptionsNearby from '../OptionsNearby';
import VenueMap from '../VenueMap';
import MainSlider from '../MainSlider';
import FacilitiesImages from '../FacilitiesImages';
import RoomCardSlider from '../RoomCardSlider';
import SlideShow from '../SlideShow';







export default class Venue extends React.Component {
	constructor() {
		super();

		this.state = {
			timeOfMeeting: {}
		}

		this.updateTime = this.updateTime.bind(this);
	}

	updateTime(timeOfMeeting) {
		const time = this.state.timeOfMeeting;
		this.setState({ timeOfMeeting: timeOfMeeting });
	}
  render () {
		// window.test = this.state.timeOfMeeting;
		// console.log(this.state.timeOfMeeting);
    return (
      <div className="venue">

        <SlideShow/>


			<VenueFilters time={this.state.timeOfMeeting} updateTime={this.updateTime}/>

				<div className="row">
          <div className="mainSliderContent col-xs-12 col-md-12 col-lg-12">
					</div>
        </div>

				<div id="venue_rooms" data={this.state.timeOfMeeting}>
				</div>

				<div className="row venueContainer">
          <div className="col">
						<OptionsNearby venue = {2}/>
					</div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <VenueMap/>
          </div>
        </div>



      </div>
    );
  }
}
