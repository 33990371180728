import React from 'react';
import './FaciliteInformationBox.scss';


export default class FaciliteInformationBox extends React.Component {


	render () {
    return (

			<div className="FacilitiesInfo">
				<div className="FacilitiesInfoBox">
					<h3 className="white">{venueData.plantName}</h3>
					<div className="longInfo"><p>{venueData.plantFacts}</p></div>
					<div className="shortInfo">
						<p>
							Fakta om Marstrands Havshotell Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sem justo, faucibus ac porta at, aliquet tempus nisi. Aenean viverra, ante eget luctus blandit, augue nisi imperdiet nibh, in pulvinar elit ante ut diam.
						</p>
					</div>
					<div className="readMore pull-right"><p>Mer info...</p></div>
				</div>
				<div className="FacilitiesInfoBoxLarge col-sm-10 col-md-10 col-md-offset-1">
					<div className="col-sm-11 col-md-11">
						<h3 className="white">{venueData.plantName}</h3>
					</div>
					<div className="closeInfoBox col-sm-1 col-md-1 pull-right">
						<h3>x</h3>
					</div>
					<div className="col-sm-12 col-md-8 divider">
						<p>{venueData.plantFacts}</p><br/>
						<p>{venueData.plantFacts}</p><br/>
					</div>

					<div className="InfoBoxAlt col-sm-12 col-md-4">

							<h5 className="InfoBoxAlt"> Sittnings Alternativ</h5>
							<div className="col-xs-4 col-sm-4 col-md-6">
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_bio.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Bio</p>
									</div>
								</div>
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_mingel.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Mingel</p>
									</div>
								</div>
							</div>

							<div className="col-xs-4 col-sm-4 col-md-6">
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_oar.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Öar</p>
									</div>
								</div>
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_skolsittning.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Skolsittning</p>
									</div>
								</div>
							</div>

							<div className="col-xs-4 col-sm-4 col-md-6">
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_u_bord.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Ubord</p>
									</div>
								</div>
							</div>

							<div className="col-xs-4 col-sm-4 col-md-6">
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_o_bord.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Obord</p>
									</div>
								</div>
							</div>

							<h5 className="InfoBoxAlt">Utrustning</h5>
							<div className="col-xs-4 col-sm-4 col-md-6">
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_Wifi.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>WiFi</p>
									</div>
								</div>
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_proj.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Projektor</p>
									</div>
								</div>
							</div>

							<div className="col-xs-4 col-sm-4 col-md-6">
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_speaker.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Högtalare</p>
									</div>
								</div>
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_interctive_screen.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Skärm</p>
									</div>
								</div>
							</div>

							<div className="col-xs-4 col-sm-4 col-md-6">
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_Whiteboard.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Whiteboard</p>
									</div>
								</div>
							</div>

							<div className="col-xs-4 col-sm-4 col-md-6">
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_pingpong.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Pingisbord</p>
									</div>
								</div>
							</div>

							<h5 className="InfoBoxAlt">Mat & dryck</h5>
							<div className="col-xs-4 col-sm-4 col-md-6">
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_coffe.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Kaffe/te</p>
									</div>
								</div>
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_fruit.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Frukt</p>
									</div>
								</div>
							</div>

							<div className="col-xs-4 col-sm-4 col-md-6">
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_fruits.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Förmiddagsfika</p>
									</div>
								</div>
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_lunch.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Lunch</p>
									</div>
								</div>
							</div>

							<div className="col-xs-4 col-sm-4 col-md-6">
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_eftermiddagFika.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Eftermiddagsfika</p>
									</div>
								</div>
							</div>

							<div className="col-xs-4 col-sm-4 col-md-6">
								<div className="InfoBoxIcons row">
									<div className="col-xs-4 col-sm-4 col-md-6">
										<img src="assets/images/icons/w_cake.svg" alt=""/>
									</div>
									<div className="col-xs-4 col-sm-4 col-md-6">
										<p>Tårta</p>
									</div>
								</div>
							</div>

					</div>


				</div>
			</div>

    );
  }
}
