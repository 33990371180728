import React from 'react';
import './VenueFilters.scss'
import ShowSearchForm from '../ShowSearchForm';

export default class VenueFilters extends React.Component {

	componentDidMount(){
		$(".venue_filters").sticky();
		$(".venue_filters").on('sticky-start', function() {
			$(".addonsNext").css("display", "none")
			$("#venue_filters, #search_form").css("display", "none")
			$("#sticky-wrapper").addClass("sticky-mini")
			$(".sticky-wrapper .venue_filters").addClass("minimized")
			$("#backToTop").css("display", "block")
			$("#backToTop").on("click", function() {
				$(".addonsNext").css("display", "block")
				$("#sticky-wrapper").removeClass("sticky-mini")
				$("#venue_filters, #search_form").css("display", "block")
				$(".sticky-wrapper .minimized").removeClass("minimized")
				window.scrollTo(0, 300)
			})
		});
		$(".venue_filters").on('sticky-end', function() {
			$(".addonsNext").css("display", "block")
			$("#sticky-wrapper").removeClass("sticky-mini")
			$(".sticky-wrapper .minimized").removeClass("minimized")
			$("#venue_filters, #search_form").css("display", "block")
			$(".sticky-wrapper .venue_filters").css({"height": "", "display": "block"})
			$("#backToTop").css("display", "none")
		});

	}

  render () {
    return (
      <div className="venue_filters">
				<div id="venue_filters">
					<div>
						<h1 className="name">{ venueData.plantName }</h1>
						<p className="place">
							{`${venueAddress.street} ${venueAddress.city.name}`}
						</p>
					</div>
				</div>
				<button className="btn btn-link" id="backToTop">Till Filter</button>
				<ShowSearchForm time={this.props.time} updateTime={this.props.updateTime}/>
					<div className="row">
						<div className="ok">
						<p className="form_notice">Ange antal personer och datum för din bokning</p>
						</div>
					</div>
      </div>
    );
  }
}
