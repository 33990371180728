import React from 'react'

export default class FacilitiesImages extends React.Component {
  render () {
    return (
<div className="row">

  <div className="col-xs-8">
    <img  alt="" width="100%" src="https://dev-be.timetomeet.se/static/crb/media/20170201/Skarmavbild_2017-02-01_kl._09.33.24.png"/>
  </div>

  <div className="col-xs-2">
    <img  alt="" width="100%" src="https://dev-be.timetomeet.se/static/crb/media/20170201/Skarmavbild_2017-02-01_kl._09.33.24.png"/>
    <img  alt="" width="100%" src="https://dev-be.timetomeet.se/static/crb/media/20170201/Skarmavbild_2017-02-01_kl._09.33.24.png"/>
    <img  alt="" width="100%" src="https://dev-be.timetomeet.se/static/crb/media/20170201/Skarmavbild_2017-02-01_kl._09.33.24.png"/>
    <img  alt="" width="100%" src="https://dev-be.timetomeet.se/static/crb/media/20170201/Skarmavbild_2017-02-01_kl._09.33.24.png"/>
    <img  alt="" width="100%" src="https://dev-be.timetomeet.se/static/crb/media/20170201/Skarmavbild_2017-02-01_kl._09.33.24.png"/>
  </div>
</div>


    );
  }
}
