import React from 'react'

import './SearchResults.scss';
import './SearchResults.js';
import '../SlideShow/SlideShow.js';



export default class SearchResults extends React.Component {

	state = {
		selected: "fullDayPrice"
	}



	componentDidMount(){
		$("#options_nearby").removeClass("hidden-div");
    $(".loading").fadeOut("slow");

		//
		let test = window.selected;
		// console.log(window);
		// console.log(this.selecter);
		// console.log(document.querySelectorAll(".venue_rooms"));
		// //FROM SearchResults
		// //Dynamic change values in card based on time of conferance
		//
			let selectBox = document.querySelector(".selectBoxTimeOfMeeting");
			selectBox.addEventListener("click", ()=>{
				// console.log(document.querySelector("#venue_rooms").getAttribute('data'));
			let selected = document.querySelector("#venue_rooms").getAttribute('data');
			// console.log(selected);
			// window.selectedFromSelect = selected;
			this.setState({ selected: selected });
			// console.log(this.state.selected);
			});


	}

	bookingLinkHandle(event){
		event.preventDefault();
	}

// console.log(t);
	calculatePriceType(e, t, a){
		e = e.split(":"),
    t = t.split(":"),
    e = e[0],
    t = t[0];
    var r;
    parseInt(t) - parseInt(e);
    return r = 10 >= e && t >= 14 ? "fullDayPrice" : e > 12 ? "afterNoonPrice" : "preNoonPrice"
	}

	plantToArray(e){
		var t = [];
        for (var a in e)
            t[t.length] = e[a];
        return t
	}

	bookingHandleClick(e){
		e.preventDefault();
    var t = $("[name=persons]").val(),
        a = $("[name=date]").val();

    if(t && a) {
      document.location.href = e.currentTarget.href
    } else {
      !a ? $(".venue_filters [name=date]").addClass("error") : $(".venue_filters [name=date]").removeClass("error");
      !t ? $(".venue_filters [name=persons]").addClass("error") : $(".venue_filters [name=persons]").removeClass("error");

      !a || !t ? $(".venue .venue_filters .ok").show() : $(".venue .venue_filters .ok").hide();

      if(!t) $("[name=persons]").focus();
      else if(!a) $("[name=date]").focus();
    }
	}

	renderNoRooms(){
		return (
			<div id="venue_rooms">
				<div className="venue_rooms v_info">
					<div className="heading">
						<h2 className="name">{ lang.venueAvailableRooms }</h2>
					</div>
					<div className="row">
						<div className="alert alert-danger">
							{ lang.venueNoRooms }
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderFacilitesIcons(item){

		// console.log(this.state);
    return (
			<div data-slide-index={item} className="imgIcon col-xs-3 col-sm-3 col-md-1 addMargin">
				<img className="onHover" src={item.url} title={item.name} alt={item.name}/>
			</div>
    );
  }

	renderTeknikIcons(item){
// console.log(item.name);
    return (
			<div data-slide-index={item} className="imgIcon col-xs-3 col-sm-3 col-md-1 addMargin">
				<img className="onHover" src={item.url} title={item.name} alt={item.name}/>
			</div>

    );
  }


	renderImg(item){
	// console.log(item.id_room);
	// console.log(venueRooms);
    return (
			<img data-slide-index={item} id="noMarginOrPadding" name={item.id_room} className="smallImg col-xs-12 col-sm-2 col-md-2 img" src={item.imgUrl} alt="" />
    );
  }

	renderRoom(e){
// console.log(e.img);

		let t = $("[name=persons]").val(),
        dateVal = $("[name=date]").val();

		let a = {
        venue: getParams.venue
    };

		var strFrom = lang.searchFrom.toLowerCase(),
		pre=false,
		aft=false,
		full=false,
		preUrl="",
		aftUrl="",
		fullUrl="",
		generalClass="",
		roomClass="",
		preClass="",
		aftClass="",
		fullClass="",
		preBookNow=lang.bookNow,
		aftBookNow=lang.bookNow,
		fullBookNow=lang.bookNow,
		preText=!dateVal?lang.venuePreNoon+" "+strFrom:lang.venuePreNoon,
		aftText=!dateVal?lang.venueAfterNoon+" "+strFrom:lang.venueAfterNoon,
		fullText=!dateVal?lang.venueFullDay+" "+strFrom:lang.venueFullDay,
		prePrice=Math.round(e.preNoonPrice) + " kr",
		aftPrice=Math.round(e.afterNoonPrice) + " kr",
		fullPrice=Math.round(e.fullDayPrice) + " kr",
		fullStart=e.preNoonAvailabilityHourStart,
		fullEnd=e.preNoonAvailabilityHourEnd,
		fullPriceTmp="",
		preTime=e.preNoonAvailabilityHourStart.slice(0, -3)+"-"+e.preNoonAvailabilityHourEnd.slice(0, -3),
		aftTime=e.afterNoonAvailabilityHourStart.slice(0, -3)+"-"+e.afterNoonAvailabilityHourEnd.slice(0, -3),
		fullTime=e.preNoonAvailabilityHourStart.slice(0, -3)+"-"+e.afterNoonAvailabilityHourEnd.slice(0, -3);


		if(dateVal){
			preClass="disabled",
			aftClass="disabled",
			fullClass="disabled";
		}

		if($("[name=persons]").val()>e.maxSeats){
			roomClass="hidden";
		}


		if(this.props.preNoon.conferenceRoomAvailability){

			this.props.preNoon.conferenceRoomAvailability.map(function(r) {
				if(r.conferenceRoom==e.id){

					pre=r.id;
					a.from = r.hoursAvailableFrom.slice(0, -3);
					a.to = r.hoursAvailableTo.slice(0, -3);
					a.id = r.block;
					a.price = Math.round(r.preNoonPrice);
					a.room = r.conferenceRoom;
					a.booking = r.id;
					a.date = r.start;
					a.persons = $("[name=persons]").val();

					var url = "";
					for (var v in a) url += "&" + v + "=" + a[v];

					preUrl= options.bookingPageUrl + "?" + url.substring(1);
					preClass="";

					prePrice=a.price + " kr"

					fullPriceTmp= Math.round(r.fullDayPrice);
					fullStart=a.from;
					full=r.block;

				}
			})
		}

		// Afternoon
		if(this.props.afterNoon.conferenceRoomAvailability){
			this.props.afterNoon.conferenceRoomAvailability.map(function(r) {
				if(r.conferenceRoom==e.id){
					aft=r.id;

					a.from = r.hoursAvailableFrom.slice(0, -3);
					a.to = r.hoursAvailableTo.slice(0, -3);
					a.id = r.block;
					a.price = Math.round(r.afterNoonPrice);
					a.room = r.conferenceRoom;
					a.booking = r.id;
					a.date = r.start;
					a.persons = $("[name=persons]").val();

					var url = "";
					for (var v in a) url += "&" + v + "=" + a[v];

					aftUrl= options.bookingPageUrl + "?" + url.substring(1);
					aftClass="";

					aftPrice=a.price + " kr"


					fullEnd=a.to;

				}
			})
		}


		// Full day
		if(pre && aft){
			a.from = fullStart;
			a.to = fullEnd;
			a.id = full;
			a.price = fullPriceTmp;
			a.booking = pre+","+aft;
			a.persons = $("[name=persons]").val();

			var url = "";
			for (var v in a) url += "&" + v + "=" + a[v];

			fullUrl= options.bookingPageUrl + "?" + url.substring(1);
			fullClass="";
			fullPrice= a.price + " kr";

			fullBookNow=lang.bookNow;

		}

		if(pre || aft) roomClass="", generalClass="hidden-div";

		aftBookNow= dateVal && !aft ? lang.venueBooked:lang.bookNow;
		preBookNow= dateVal && !pre ? lang.venueBooked:lang.bookNow;
		fullBookNow= dateVal && !aft && !pre ? lang.venueBooked:lang.bookNow;

		if($("[name=persons]").val()>e.maxSeats || (dateVal && (!pre && !aft))){
			roomClass="hidden";
		}

		var l = e.imgUrl?e.imgUrl:"assets/images/noimg.gif";

		var i = [];

		for (var r in e.defaultSeating)
		i.push(e.defaultSeating[r].name);

// get results from dropdaown and use it
		var u = e.description ? e.description : e.conferenceRoomDescription;

		var genText = lang.price + " " + Math.round(e.fullDayPrice) + " kr";
		// console.log(e.tech);
		var roomId = e.conferenceRoomId ? e.conferenceRoomId : e.id;
// 		let id = arguments[1];
// console.log(e.defaultSeating);
let getTime= this.state.selected;
let currentPrice = this.state.selected;
let timeOfEvent = "Heldag"

if (getTime === "fullDayPrice") {
	currentPrice = fullPrice.replace(" kr", ":-");
	timeOfEvent = "Heldag"
} else if  (getTime === "preNoonPrice") {
	timeOfEvent = "Förmiddag"
	currentPrice = prePrice.replace(" kr", ";-");
} else {
	timeOfEvent = "Eftermiddag"
	currentPrice =  aftPrice.replace(" kr", ";-");
}

		return (
			<div key={`room_${roomId}`}>
				<div className="mainBox col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 pull-left" id="noMarginOrPadding">

				    <div className="mainPic col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" id="noMarginOrPadding">

				      <img id="noMarginOrPadding" className="mainImg col-xs-12 col-sm-12 col-md-10" src={e.imgUrl} name={e.id} alt="" />
								{e.img.map(this.renderImg)}

				    </div>
				  </div>

				  <div className="infoBox col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">

				    <div className="row border xsRowContent"  id="noMarginOrPadding">

				    </div>

						<div className="row border mediumRowContent"  id="noMarginOrPadding">
							<div className="col-xs-6 col-sm-12 col-md-8">
							  <h2 className="headName">{ e.name.toUpperCase() }</h2>
							</div>
							<div className="col-xs-6 col-sm-12 col-md-4">
							  <h2>Från {currentPrice}</h2>
							</div>
				    </div>

						<div className="row border largeRowContent"  id="noMarginOrPadding">
							<div className="col-xs-6 col-sm-6 col-md-5 col-lg-6">
								<h4>{ lang.maxNo.toUpperCase() }:<br/> { e.maxSeats } Personer</h4>
							</div>
							<div className="col-xs-6 col-sm-6 col-md-3 col-lg-2">
								<h3>{timeOfEvent}</h3>
							</div>
							<div className="bookingButton col-xs-12 col-sm-12 col-md-4">
								<button type="button"><h3>Nästa steg</h3></button>
							</div>
				    </div>

						<div className="row border smallRowContent"  id="noMarginOrPadding">
							<div className="col-xs-6 col-sm-6 col-md-6">
							  <h4>FACILITETER:</h4>
							</div>
							<div className="col-xs-6 col-sm-6 col-md-2">
							  <h4 class="facilityOptionsHeadline">TEKNIK:</h4>
							</div>
				    </div>

						<div className="row border largeRowContent2">
							{e.defaultSeating.map(this.renderFacilitesIcons.bind(this))}

							{/*<div className="col-xs-3 col-sm-3 col-md-1 hideElement">
								<img src="assets/images/icons/more.png" alt="More"/>
							</div>*/}

							<div className="col-xs-3 col-sm-3 col-md-2 spacer"></div>

								{e.tech.map(this.renderTeknikIcons)}

							{/*<div className="col-xs-3 col-sm-3 col-md-1 hideElement">
								<img src="assets/images/icons/more.png" alt=""/>
							</div>*/}
				    </div>
				  </div>
			</div>
		);

	}


  render () {
		// console.log(window.selectedFromSelect);

		let rooms = [];
		let t = $("[name=persons]").val(),
        dateVal = $("[name=date]").val();
    rooms = this.plantToArray(venueRooms);
    // console.log(rooms);

		// if ((!this.props.preNoon.conferenceRoomAvailability && !this.props.afterNoon.conferenceRoomAvailability && dateVal) || !rooms.length){
		// 	return this.renderNoRooms();
		// }
    return (
				<div className="venue_rooms v_info">
					<div className="heading">
						<div className="row">
							{ rooms.map(this.renderRoom.bind(this)) }
						</div>
				</div>
			</div>
    );
  }
}
