
// Adapts box based on img size
function AdaptBox(){
  let height = document.querySelector(".mainImg").clientHeight;
  let infoBox = document.querySelectorAll(".infoBox");
  for (var i = 0; i < infoBox.length; i++) {
    infoBox[i].setAttribute("style",'height:' +height+'px');
  }
}

window.onload = function(event) {
  AdaptBox();

  //FROM FaciliteInformationBox
  //Enlarge information box
  let readMore = document.querySelector(".readMore");
  let FacilitiesInfoBox = document.querySelector(".FacilitiesInfoBox");
  let FacilitiesInfoBoxLarge = document.querySelector(".FacilitiesInfoBoxLarge");
  // console.log(FacilitiesInfoBox);
  readMore.addEventListener("click", function(){
    // document.body.classList.add("overlay");
    // FacilitiesInfoBoxLarge.style.backgroundColor = "red";
    FacilitiesInfoBox.style.display = "none";
    FacilitiesInfoBoxLarge.style.display = "block";
        // console.log(FacilitiesInfoBoxLarge);
      });

  //FROM FaciliteInformationBox
  //Close information box
  let closeInfoBox = document.querySelector(".closeInfoBox");
  closeInfoBox.addEventListener("click", function(){
    FacilitiesInfoBox.style.display = "block";
    FacilitiesInfoBoxLarge.style.display = "none";
    });



  // FROM SlideShow
  // change big picure when clicking on the small one
  let mainSlideImg = document.querySelector(".mainSlideImg");
  let slideImg = document.querySelectorAll(".slideImg");

  for (var i = 0; i < slideImg.length; i++) {
    slideImg[i].addEventListener("click", function(){
      mainSlideImg.src=this.src;
    });





		// //animations
		// $(document).ready(function() {
		// 	 $(".slideImg").click(function(){
		// 		let clone = $(this).clone();
		// 		console.log(clone);
		// 		clone.css('position','fixed');
		// 		// console.log(this);
		// 			clone.animate({
		// 				 width: "83%",
		// 			}, 1000 ).fadeOut( "fast" );
		// 	 });
		// });
	// 	//FROM SearchResults
	// 	//Dynamic change values in card based on time of conferance
	// 		let selectBox = document.querySelector(".selectBoxTimeOfMeeting");
	// 		selectBox.addEventListener("click", function(){
	// 		let selected = selectBox.options[selectBox.selectedIndex].value;
	// 		window.selectedFromSelect = selected;
	// 		// console.log(selected);
	// });
  }

//
//
//
  //FROM SearchResults
  // change big picure when clicking on the small one
  let mainPic = document.querySelectorAll(".mainImg");
  let smallImg = document.querySelectorAll(".smallImg");
  for (var i = 0; i < smallImg.length; i++) {
      smallImg[i].addEventListener("click", function(){
        for (var i = 0; i < mainPic.length; i++) {
          if (mainPic[i].name == this.name) {
            mainPic[i].src=this.src;
          }
        }
    });
  }

}


window.onresize = function(event) {
  let innerWidth = window.innerWidth;
  if (innerWidth > 700 ) {
    AdaptBox();
  }
};
