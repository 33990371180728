import React from 'react'

import './VenueImage.scss';

export default class VenueImage extends React.Component {
  render () {
    return (
      <div className="venue-image">
				VenueImage
      </div>
    );
  }
}
